<template>
  <div
    class="w-full relative rounded-xl grid"
    :class="mode === 'tile' ? 'p-4 card_tile' : 'py-4 px-5 card_simple'"
    style="box-shadow: 0px 4px 20px 0px #48507029"
  >
    <div class="">
      <nuxt-link :to="`/product/${product.public_id}`">
        <img
          :src="product?.imageUrl ? product.imageUrl : IMAGE_DEFAULT"
          alt=""
          class="object-center object-contain rounded"
          :class="
            mode === 'tile'
              ? 'w-20 h-20 sm:w-44 sm:h-44 mx-auto mt-0 sm:mt-11'
              : 'w-20 sm:w-44 h-20 sm:h-44'
          "
        />
      </nuxt-link>
    </div>
    <div
      class="flex flex-col"
      :class="mode === 'tile' ? 'sm:space-y-2' : ' justify-between pr-6 sm:pr-0'"
    >
      <div class="grid grid-rows gap-3">
        <div>
          <template v-if="product.isNew || product.count === 0">
            <div
              :class="[
                mode === 'tile'
                  ? 'flex mb-4 relative sm:absolute sm:mb-0 sm:top-4 sm:left-4'
                  : '',
                product.count === 0 ? 'bg-red' : 'bg-green',
              ]"
              class="flex justify-center whitespace-nowrap items-center text-white px-3 max-w-min h-5 sm:h-7 rounded font-lato text-xs sm:text-base"
            >
              {{ product.count === 0 ? "нет в наличии" : "новинка" }}
            </div>
          </template>
          <template v-else-if="product.count > 0">
            <div
              :class="
                mode === 'tile'
                  ? 'flex mb-4 relative sm:absolute sm:mb-0 sm:top-4 sm:left-4'
                  : ''
              "
              class="card_shadow space-x-2 bg-white flex whitespace-nowrap justify-center items-center px-2.5 max-w-min h-5 sm:h-7 rounded"
            >
              <p class="font-medium text-black font-lato text-xs sm:text-base">
                в наличии
              </p>
              <p-icon-check></p-icon-check>
            </div>
          </template>
        </div>

        <div
          class="font-lato text-base sm:text-lg card__simple_title !leading-tight font-medium"
          v-if="mode === 'simple'"
        >
          <nuxt-link :to="`/product/${product.public_id}`"> {{ product.name }}</nuxt-link>
        </div>
        <div
          v-if="mode === 'simple'"
          class="font-lato hidden card__simple_snippet md:block text-sm text-gray-600 sm:text-base"
        >
          {{ product.snippet }}
        </div>
      </div>

      <div
        class="hidden sm:flex flex-row space-x-4 items-center"
        :class="mode === 'tile' ? 'justify-between !mt-0' : ''"
      >
        <div class="font-lato text-xs sm:text-sm text-gray-500">
          {{ ` Арт. ${product.code}` }}
        </div>
        <div class="pb-1.5">
          <p-grade></p-grade>
        </div>
      </div>
      <div
        class="font-lato text-base sm:text-lg !leading-tight font-medium"
        :class="mode === 'tile' ? 'card__tile_title  pr-6 sm:pr-0' : 'hidden'"
      >
        <nuxt-link :to="`/product/${product.public_id}`"> {{ product.name }}</nuxt-link>
      </div>
    </div>
    <div class="font-lato text-xs my-auto sm:text-sm text-gray-500 sm:hidden">
      {{ ` Арт. ${product.code}` }}
    </div>
    <div class="sm:hidden my-auto ml-auto">
      <p-grade :size="8" :isMin="true"></p-grade>
    </div>

    <div class="font-lato text-xl my-auto sm:text-3xl font-semibold sm:hidden">
      {{ formatMoneyWithCurrency(product.price) }}
    </div>
    <p-button
      v-if="!isInCart"
      font-size="base"
      :disabled="product.count === 0"
      class="w-29 h-9 font-lato sm:hidden ml-auto"
      @click="addProductToCart"
    >
      Купить</p-button
    >
    <p-button
      class="w-29 h-9 sm:h-10 text-base sm:hidden ml-auto"
      to="/cart"
      v-else
      type="primary-light"
      >В корзине</p-button
    >
    <div
      class="hidden sm:flex flex-col justify-between"
      :class="mode === 'tile' ? '' : 'items-end'"
    >
      <div
        :class="mode === 'tile' ? 'hidden' : ''"
        class="flex flex-row space-x-5 px-4 py-3 rounded-lg"
        style="box-shadow: 0px 0px 20px 0px #48507014"
      >
        <p-icon-favorite-black
          class="cursor-pointer"
          :isActive="isFavorite"
          @click="addToFavorites(product.public_id)"
        />
        <div class="w-[1px] h-full sm:bg-primary-25 rounded"></div>

        <p-icon-share class="cursor-pointer"></p-icon-share>
      </div>

      <div
        :class="mode === 'tile' ? 'sm:flex hidden' : 'hidden'"
        class="flex-row space-x-5 px-4 py-3 rounded-lg mb-4 relative sm:absolute sm:mb-0 sm:top-2 sm:right-0"
      >
        <p-icon-favorite-black
          class="cursor-pointer"
          :isActive="isFavorite"
          @click="addToFavorites(product.public_id)"
        />
      </div>
      <div
        :class="
          mode === 'tile'
            ? 'flex flex-row justify-between items-center'
            : 'flex flex-col space-y-4 items-end'
        "
      >
        <div
          class="font-lato font-semibold"
          :class="mode === 'tile' ? 'text-xl' : 'text-3xl'"
        >
          {{ formatMoneyWithCurrency(product.price) }}
        </div>
        <p-button
          v-if="!isInCart"
          :disabled="product.count === 0"
          class="w-33 h-10 text-base sm:text-lg font-lato"
          @click="addProductToCart"
        >
          Купить
        </p-button>
        <p-button class="w-33 h-10 text-base" to="/cart" type="primary-light" v-else
          >В корзине</p-button
        >
      </div>
    </div>
    <div class="block sm:hidden absolute right-4 top-4">
      <p-icon-favorite-black
        class="cursor-pointer w-5 h-5"
        :isActive="isFavorite"
        @click="addToFavorites(product.public_id)"
      />
    </div>
    <teleport to="body" class="fixed left-0 top-0 w-full h-screen">
      <div :class="isFavoriteAdded ? 'confirmation flex' : 'hidden'">
        <p-wrapper class="flex flex-row items-center justify-center p-4 gap-2">
          <p-icon-favorite-black class="cursor-pointer w-5 h-5" :isActive="true" />
          <div class="font-lato text-base text-gray-900">Товар добавлен в избранное</div>
        </p-wrapper>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import IMAGE_DEFAULT from "~/assets/svgs/placeholder.svg";
import { useFavoritesStore } from "~/stores/use-favorite-store";
const { formatMoneyWithCurrency } = useMoneyFormater();

const props = defineProps({
  mode: {
    type: String,
    default: "simple", //tyle || simple
  },
  product: {
    type: Object,
    required: true,
  },
});

const { addProductToCart, isInCart } = useCart(props.product);

const favoritesList = computed(() => {
  const { favorites } = useFavoritesStore();
  return favorites;
});

const { removeFavoriteProduct, addFavoriteProduct } = useFavoritesStore();

const isFavorite = computed(() =>
  favoritesList.value.some((element) => props.product.public_id === element.productId)
);

const isFavoriteAdded = ref(false);
function addToFavorites(id) {
  if (isFavorite.value) {
    removeFavoriteProduct(id);
  } else {
    addFavoriteProduct(id);
    isFavoriteAdded.value = true;
    setTimeout(() => {
      isFavoriteAdded.value = false;
    }, 2000);
  }
}
</script>

<style scoped>
.confirmation {
  width: 100%;
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.card_shadow {
  box-shadow: 0px 4px 20px 0px #48507029;
}

@media (min-width: 769px) {
  .card_tile {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 220px minmax(auto, 105px) 40px;
  }

  .card_simple {
    grid-template-columns: 176px minmax(0, 1fr) 138px;
    /* grid-template-rows: 1fr 32px 20px; */
    grid-gap: 0 20px;
  }

  .card__simple_snippet {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  .card__simple_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  .card__tile_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
  }
}

@media (max-width: 768px) {
  .card_tile {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 220px minmax(auto, 105px) 40px;
  }

  .card_simple {
    grid-template-columns: 176px minmax(0, 1fr) 138px;
    /* grid-template-rows: 1fr 32px 20px; */
    grid-gap: 0 20px;
  }

  .card__tile_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
  }

  .card__simple_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
  }

  .card__simple_snippet {
    display: hidden;
  }
}

@media (max-width: 640px) {
  .confirmation {
    bottom: 60px;
  }

  .card_tile {
    grid-template-columns: auto 1fr;
    grid-template-rows: 80px 13px 40px;
    grid-gap: 12px 12px;
  }

  .card__tile_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  .card__simple_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  .card_simple {
    grid-template-columns: auto 1fr;
    grid-template-rows: 80px 13px 40px;
    grid-gap: 12px 12px;
  }
}
</style>
