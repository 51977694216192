<template>
  <svg
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.61405L3.456 7.07005L9.596 0.930054"
      stroke="#00AF12"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
