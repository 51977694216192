<template>
  <ClientOnly>
    <star-rating
      v-model:rating="rating"
      :star-size="size"
      :increment="0.5"
      active-color="#FFA267"
      active-border-color="#FFA267"
      inactive-color="#fff"
      border-color="#FFA267"
      :border-width="2"
      :show-rating="false"
      :padding="2"
      :readOnly="readOnly"
      :class="isMin ? 'minHeght' : ''"
    ></star-rating>
  </ClientOnly>
</template>

<script setup>
import StarRating from "vue-star-rating";

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  size: {
    type: Number,
    default: 10,
  },
  readOnly: {
    type: Boolean,
    default: true,
  },
  isMin: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const rating = computed({
  get() {
    return props.modelValue;
  },
  set() {
    emit("update:modelValue");
  },
});
</script>

<style scoped>
.minHeght {
  height: 0.7rem;
}
</style>
