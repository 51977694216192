<template>
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
    @click="changeColor"
  >
    <path
      d="M16 21.5C17.6569 21.5 19 20.1569 19 18.5C19 16.8431 17.6569 15.5 16 15.5C14.3431 15.5 13 16.8431 13 18.5C13 20.1569 14.3431 21.5 16 21.5Z"
     :stroke="stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 7.5C17.6569 7.5 19 6.15685 19 4.5C19 2.84315 17.6569 1.5 16 1.5C14.3431 1.5 13 2.84315 13 4.5C13 6.15685 14.3431 7.5 16 7.5Z"
     :stroke="stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 14.5C5.65685 14.5 7 13.1569 7 11.5C7 9.8431 5.65685 8.5 4 8.5C2.34315 8.5 1 9.8431 1 11.5C1 13.1569 2.34315 14.5 4 14.5Z"
     :stroke="stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13.5 6L6.5 10" :stroke="stroke" stroke-width="1.5" />
    <path d="M6.5 13L13.5 17" :stroke="stroke" stroke-width="1.5" />
  </svg>
</template>
<script setup>
const changeColor = () => {
  stroke.value = "#DD672C";
};
const props = defineProps({
  isActive: {
    type: Boolean,
  },
});

const stroke = ref("#3A405A");
const isHover = ref(false);
const fill = computed(() => {
  if (props.isActive === true) {
    stroke.value = "#FE7F2D";
    return "#FE7F2D";
  } else {
    stroke.value = "#3A405A";
    return "#FFFFFF";
  }
});

watch(isHover, () => {
  if (isHover.value || props.isActive) {
    stroke.value = "#FE7F2D";
  } else {
    stroke.value = "#3A405A";
  }
});
</script>
