<template>
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    <path
      d="M23 8.01358C23 9.73189 22.3468 11.3823 21.1804 12.6032C18.4954 15.4145 15.8911 18.3459 13.1058 21.0552C12.4674 21.6672 11.4546 21.6449 10.8437 21.0052L2.81913 12.6032C0.393622 10.0636 0.393622 5.96359 2.81913 3.42397C5.26848 0.859388 9.25873 0.859388 11.7081 3.42397L11.9998 3.72936L12.2913 3.42415C13.4656 2.19389 15.065 1.5 16.7358 1.5C18.4066 1.5 20.0059 2.19382 21.1804 3.42397C22.3469 4.64495 23 6.2953 23 8.01358Z"
      :stroke="stroke"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  isActive: {
    type: Boolean,
  },
});

const stroke = ref("#3A405A");
const isHover = ref(false);
const fill = computed(() => {
  if (props.isActive === true) {
    stroke.value = "#FE7F2D";
    return "#FE7F2D";
  } else {
    stroke.value = "#3A405A";
    return "#FFFFFF";
  }
});

watch(isHover, () => {
  if (isHover.value || props.isActive) {
    stroke.value = "#FE7F2D";
  } else {
    stroke.value = "#3A405A";
  }
});
</script>

<style scoped></style>
